@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  color: white;
}
:root {
  --fine_card_width: 270px;
  --fine_card_height: 330px;
  --card-width: 290px;
  --card-height: 450px;
}

::selection {
  background: rgb(182, 145, 255);
  color: rgb(77, 24, 180);
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background: linear-gradient(
    100deg,
    rgb(54, 17, 124) 0%,
    rgb(77, 24, 180) 40%,
    rgb(182, 145, 255) 100%
  );
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
}

/* Navbar */

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding-bottom: 30px;
}

.logoNav {
  min-width: 150px;
  min-height: 100px;
  max-width: 200px;
  max-height: 150px;
  width: 20%;
  height: auto;
  margin: 20px;
  margin-bottom: 0;
}

.navContent {
  display: flex;
  flex-direction: row;
}

.nav_button {
  background-color: rgba(255, 255, 255);
  overflow: hidden;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.6s all;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
  margin-right: 30px;
  overflow: hidden;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1em;
  color: rgb(134, 94, 212);
  width: auto;
  height: auto;
  transition: 0.6s all;
}

.nav_button:hover {
  background-color: rgb(134, 94, 212);
  color: white;
}

.accountImg {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  width: 60px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}
.accountImg img {
  aspect-ratio: 1/1;
  width: 45px;
}
/* presentation section */

.presentation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.presentation_image {
  min-width: 300px;
  min-height: 300px;
  max-width: 450px;
  max-height: 460px;
  width: 50%;
  height: auto;
  margin: auto;
  margin-right: 0;
  margin-left: 0;
}

@media (max-width: 1000px) {
  .presentation_image {
    margin-right: 100px;
    margin-left: 100px;
  }
}

.presentation_item {
  text-align: left;
  color: white;
  transform: translateX(-100px);
  opacity: 0;
  transition: 1s all;
}

.presentation_title {
  font-weight: bold;
  font-size: 80px;
  margin: 0;
  width: auto;
}

.presentation_text {
  font-size: 40px;
  margin: 10px 15px 30px 5px;
}
.presentation_button {
  background-color: rgba(255, 255, 255);
  overflow: hidden;
  padding: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.6s all;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
  margin-left: 5px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4em;
  color: rgb(134, 94, 212);
  width: auto;
  height: auto;
}

.presentation_button:hover {
  background-color: rgb(134, 94, 212);
  color: white;
}

/* explanation */

.explaination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83dvh;
}

.explaination .contentE {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 50px;
  transform: translateY(100px);
  opacity: 0;
}

.explaination .animation {
  transform: none;
  opacity: 1;
}

.explaination .title {
  width: 583px;
  color: #fff;
  font-size: 102px;
  font-weight: 700;
  margin-top: 0;
  margin: 10px;
}

.explaination .text {
  color: #fff;
  font-size: 40px;
  font-weight: 200;
  width: 859px;
  margin: 5px;
}

.explaination img {
  width: 586px;
  height: 578px;
  margin: 10px;
}

.result {
  margin: 0;
}

@media (max-width: 1430px) {
  .explaination .title {
    font-size: 90px;
  }
  .explaination .text {
    font-size: 30px;
    width: 700px;
  }
  .explaination img {
    width: auto;
    height: 450px;
  }
}

@media (max-width: 1170px) {
  .explaination .title {
    font-size: 80px;
  }
  .explaination .text {
    font-size: 25px;
    width: 500px;
  }
  .explaination img {
    height: 350px;
  }
}
/* Card */
.card_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1800px) {
  .card_div {
    justify-content: center;
  }
}

.card_div_title {
  font-weight: 700;
  font-size: 60px;
  color: white;
  transform: translateY(-100px);
  opacity: 0;
  transition: 1s all;
  margin: 30px;
  margin-top: 0;
  margin-bottom: 0;
  align-self: center;
}
.card_div_subtitle {
  font-weight: 500;
  margin-bottom: 70px;
  font-size: 30px;
  transform: translateY(-100px);
  opacity: 0;
  transition: 1s;
}

.card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  transition: 1s all;
}

.card {
  position: relative;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.37);
  box-shadow: 10px 10px 10px rgba(68, 68, 68, 0.5);
  backdrop-filter: blur(5px);
  font-family: "Poppins", sans-serif;
  flex: auto;
  width: var(--card-width);
  height: var(--card-height);
  transition: all 1s;
}

@media screen and (max-width: 1100px) {
  .card_container {
    gap: 60px;
  }
  .card {
    width: calc(var(--card-width) - 40px);
    height: calc(var(--card-height) - 40px);
  }
}

@media (max-height: 780px) {
  .card_div_title {
    margin-bottom: 10px;
  }
  .card_div_subtitle {
    margin-bottom: 10px;
  }
}

@media (max-height: 680px) {
  .card_div_title {
    font-size: 2rem;
  }
}

.content {
  padding: 20px;
  text-align: center;
}

.card_title {
  font-size: 2em;
  color: #fff;
  z-index: 1;
  font-weight: 700;
  transform: translateY(100px);
  transition: 0.5s;
}

.card_image {
  display: unset;
  padding: 20px;
  padding-bottom: 30px;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s;
  max-height: 100px;
  max-width: 100px;
}

.card_text {
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s;
}

.card:hover .content .card_title {
  transform: translateY(0px);
}

.card:hover .content .card_image {
  transform: translateY(0px);
  opacity: 1;
}

.card:hover .content .card_text {
  transform: translateY(0px);
  opacity: 1;
}

/* UI */

.UI {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.UiTile {
  transform: translateY(-100px);
  opacity: 0;
  transition: 1s all;
  font-size: 60px;
  margin: 0;
}

.UIContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}

.UiBtnContainer .active {
  background-color: rgb(134, 94, 212);
  color: white;
}

.UiImage {
  width: 700px;
  height: 500px;
  transition: all 1s;
}

.UiBtnContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.BtnUi {
  width: 200px;
  height: 40px;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  border: 0;
  background-color: white;
  color: rgb(134, 94, 212);
  font-size: 15px;
  font-weight: bolder;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
  transition: 0.8s all;
}

.BtnUi:hover {
  background-color: rgb(134, 94, 212);
  color: white;
  transition: all 0.8s;
}

.UIText {
  width: 700px;
  height: auto;
  text-align: start;
  font-size: 28px;
  font-weight: bold;
  transition: all 1s;
}

@media screen and (max-width: 1550px) {
  .UiImage {
    width: 650px;
    height: 400px;
  }

  .UIText {
    font-size: 25px;
  }
}

@media screen and (max-width: 1400px) {
  .UI {
    gap: 20px;
  }

  .UiImage {
    width: 500px;
  }

  .UIText {
    width: 600px;
  }
}

@media screen and (max-width: 1130px) {
  .UiImage {
    width: 450px;
    height: 350px;
  }
  .UIText {
    width: 500px;
  }
}
@media screen and (max-width: 1020px) {
  .UIContainer {
    gap: 15px;
  }
  .UIText {
    width: 450px;
  }
}

@media screen and (max-height: 700px) {
  .UiTile {
    margin-bottom: 30px;
  }
}

@media (max-height: 800px) and (max-width: 1200px) {
  .UIText {
    font-size: 20px;
  }
}

/* End */
.fine {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.endTitle {
  width: 710px;
  color: #e8e8f0;
  font-size: 90px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.endContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.endImg {
  height: 500px;
}

.endContent {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
  gap: 20px;
  transform: translateX(-100px);
  opacity: 0;
}

.endContent h1 {
  width: 700px;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.endContent h1 span {
  color: #d692ff;
}

.endContent .contentText {
  width: 650px;
  height: 150px;
  color: #fff;
  font-size: 35px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

.endCheckContainer {
  display: flex;
  align-items: center;
}

.endCheck {
  background: #d9d9d9;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  accent-color: #d692ff;
}

.endLabel {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

.endInputContainer {
  display: flex;
  align-items: center;
}

.endInput {
  border-radius: 17px;
  background: #e8e8f0;
  border: none;
  outline: none;
  color: black;
  width: 400px;
  height: 50px;
  margin-right: 35px;
}

.endButton {
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background: #e8e8f0;
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.4);
  color: #9c70f1;
  font-size: 20px;
  border: 0;
  outline: none;
  padding: 0;
  transition: 1s all;
}

.endButton:hover {
  color: #e8e8f0;
  background: #9c70f1;
  scale: 1.1;
}

.endButton:active {
  scale: 0.9;
}

@media (max-width: 1440px) {
  .endImg {
    height: 400px;
  }
  .endContent h1 {
    font-size: 45px;
    width: 600px;
  }

  .endContent .contentText {
    font-size: 30px;
    width: 600px;
    height: 100px;
  }
}

@media (max-width: 1210px) {
  .endTitle {
    font-size: 80px;
    margin-bottom: 50px;
  }
  .endImg {
    height: 300px;
  }

  .endContent h1 {
    font-size: 40px;
    width: 500px;
  }

  .endContent .contentText {
    font-size: 25px;
    width: 500px;
    height: 50px;
  }
}

@media (max-width: 1100px) {
  .endContainer {
    gap: 20px;
  }
}

@media (max-width: 1070px) {
  .endContainer {
    gap: 50px;
  }
  .endInputContainer {
    flex-direction: column;
    gap: 20px;
  }
}
/* footer */
footer {
  margin-top: 50px;
  margin-bottom: 0;
}

.footer_social {
  display: flex;
  flex-direction: row;
  gap: 30px;
  transition: 1s all;
  align-items: center;
  justify-content: center;
}

.footer_social div a img {
  transition: 0.5s all;
  height: 32px;
  width: 32px;
}
.footer_social div:hover a img {
  transform: scale(1.5);
}

.rights {
  position: relative;
  top: -1vh;
  margin-bottom: 0;
  padding-bottom: 30px;
}

.animation {
  transform: none;
  opacity: 1;
  transition: 0.6s all;
}

/* MOBILE */

@media screen and (max-width: 900px) {
  .App {
    background: linear-gradient(
      90deg,
      rgb(54, 17, 124) 0%,
      rgb(77, 24, 180) 40%,
      rgb(182, 145, 255) 100%
    );
  }

  .nav {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: linear-gradient(
      90deg,
      rgb(54, 17, 124) 0%,
      rgb(77, 24, 180) 40%,
      rgb(182, 145, 255) 100%
    );
    padding: 0;
    z-index: 99;
  }
  .nav_button {
    display: none;
  }

  .logoNav {
    margin: 0;
    margin-left: 10px;
  }
  /*presentation*/
  .presentation {
    flex-direction: column;
    margin: 0;
    margin-bottom: 200px;
    margin-top: 50px;
    transform: none;
    gap: 50px;
  }
  .presentation_image {
    margin-top: 50px;
    width: 35%;
    min-width: 200px;
    min-height: 200px;
    margin: auto;
  }
  .presentation_item {
    text-align: center;
    opacity: 1;
    transform: none;
    margin: auto;
  }
  .presentation_title {
    font-weight: bold;
    font-size: 2rem;
  }
  .presentation_text {
    font-size: 1rem;
  }
  .presentation_button {
    margin-top: 15px;
    margin: 15px;
  }

  /* explanation */

  .explaination {
    flex-direction: column;
    height: 100dvh;
    margin-bottom: 200px;
  }

  .explaination .contentE {
    transform: none;
    opacity: 1;
    text-align: center;
    margin-left: 0;
    align-items: center;
  }
  .explaination .title {
    width: auto;
    font-size: 60px;
    margin-right: 0;
    margin-left: 0;
    position: relative;
    top: -50px;
  }
  .explaination .text {
    width: 300px;
    font-size: 20px;
  }

  @media (max-width: 400px) {
    .explaination img {
      height: 250px;
    }

    @media (max-width: 355px) {
      .explaination .title {
        font-size: 50px;
      }
      .explaination .text {
        width: 280px;
      }
    }
  }
  /*card Section*/

  .card_div_title {
    font-size: 30px;
    transform: none;
    opacity: 1;
    margin-bottom: 20px;
  }
  .card_div_subtitle {
    opacity: 1;
    transform: none;
    font-size: 20px;
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
  }
  .card {
    background: rgb(95, 47, 190);
    backdrop-filter: blur(0);
    margin-left: 20px;
    box-shadow: none;
    margin-bottom: 5px;
  }
  .swiper {
    width: 300px;
  }
  .swiper-slide-shadow {
    position: unset !important;
  }

  .card_title {
    transform: none;
    opacity: 1;
  }

  .card_image {
    transform: none;
    opacity: 1;
  }

  .card_text {
    transform: none;
    opacity: 1;
  }

  /* UI */
  .UI {
    height: auto;
    margin-top: 200px;
  }
  .UiTile {
    margin-bottom: 30px;
    opacity: 1;
    transform: translateY(0);
    font-size: 40px;
    margin: 10px;
  }
  .UIContainer {
    flex-direction: column;
  }

  .UiImage {
    height: auto;
    width: 550px;
  }

  .UIText {
    font-size: 15px;
    width: 550px;
    text-align: center;
  }

  .UiContent .linea {
    margin-right: 10px;
    margin-left: 10px;
  }

  .UiBtnContainer {
    gap: 50px;
    flex-wrap: nowrap;
  }

  .BtnUi {
    aspect-ratio: 1/1;
    width: 50px;
    height: auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  @media (max-width: 550px) {
    .UiImage {
      width: 450px;
    }

    .UIText {
      width: 450px;
    }
  }

  @media screen and (max-width: 460px) {
    .UIText {
      width: 350px;
    }
    .UiImage {
      width: 350px;
    }
  }

  @media screen and (max-width: 400px) {
    .UiBtnContainer {
      gap: 20px;
    }

    .UIText {
      width: 300px;
      font-size: medium;
    }
    .UiImage {
      width: 300px;
    }
  }

  /*end*/
  .fine {
    height: auto;
    margin-top: 200px;
  }
  .endTitle {
    margin-bottom: 30px;
    font-size: 50px;
    width: auto;
  }
  .endImg {
    height: 250px;
  }
  .endContainer {
    flex-direction: column;
    gap: 10px;
  }

  .endContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    transform: none;
    text-align: center;
  }

  .endContent h1 {
    font-size: 30px;
    width: auto;
    margin: 5px;
  }

  .endContent .contentText {
    height: auto;
    width: auto;
    font-size: 20px;
    margin: 5px;
    text-align: center;
  }

  .endCheck {
    width: 20px;
    height: 20px;
  }
  .endLabel {
    font-size: 20px;
  }
  .endInput {
    margin-right: 0;
  }

  @media (max-width: 420px) {
    .endImg {
      height: 200px;
    }
    .endInput {
      width: 300px;
    }
  }
}
