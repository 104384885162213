body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  body {
    overflow: auto;
  }
}
