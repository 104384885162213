.bottone {
  background-color: rgba(255, 255, 255);
  overflow: hidden;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.6s all;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
  overflow: hidden;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2em;
  color: rgb(134, 94, 212);
  width: auto;
  height: auto;
  margin-top: 20px;
}

.bottone:hover {
  background-color: rgb(134, 94, 212);
  color: white;
}

.activeRegister {
  transform: rotateY(180deg) !important;
}

.registerState {
  aspect-ratio: 1/1.5 !important;
}

@media (max-width: 390px) {
  .bottone {
    margin-top: 0;
  }
}
